<template>
  <b-row>
    <b-col class="page-home">
      <PageHeader :title="$t('general.post')" />

      <b-row>
        <AppPost v-if="post" :post="post" />
      </b-row>

      <AppComments
        v-if="post"
        :post-id="post.id"
        :can-commit="allowComments"
        input-position-type="post"
        :callback-on-message="callbackOnMessage"
        :callback-on-message-remove="callbackOnMessageRemove"
      />
    </b-col>
  </b-row>
</template>
<script>
import Post from "@/components/models/Post";
import AppPost from "@/features/containers/post/AppPost.vue";
import PageHeader from "@/layout/PageHeader.vue";
import AppComments from "@/features/containers/Comments/AppComments.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import mux from "@/mixins/mux";

export default {
  mixins: [waitRequest, validationError, mux],
  components: {
    AppComments,
    PageHeader,
    AppPost,
  },
  computed: {
    allowComments() {
      return this.post?.allowComments;
    },
  },
  data() {
    return {
      post: null,
    };
  },
  mounted() {
    this.loadPost();
  },
  methods: {
    hasAccess(post) {
      return this.$store.state.currentUser.isAdmin || post?.hasAccess;
    },
    loadPost() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        this.$hideSpinner();
        return this.$get(
          "/posts/" + this.$route.params.id,
          (data) => {
            this.post = new Post(data);

            if (!this.hasAccess(this.post)) return this.$router.push(`/${this.$route.params.username}`);
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
    del() {
      this.$router.replace("/");
    },

    callbackOnMessage() {
      this.$set(this.post, 'commentsCount', this.post.commentsCount + 1)
    },

    callbackOnMessageRemove() {
      this.$set(this.post, 'commentsCount', this.post.commentsCount - 1)
    }
  },
};
</script>
